import React, { ChangeEvent, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { useHistory } from 'react-router-dom'

import { API, api } from 'services/api'
import { LOGIN_VIA_PASSWORD_URL } from 'utils/urls'
import { MAX_LENGTH_254, toastDefaultOptions } from 'globalConstants'
import { errorParsing, isFormDisabled, validation } from 'utils'
import { TextField, toast, Button, useGoogleReCaptcha, BrandCard } from 'App/components'
import { SuccessSubmitContent } from 'App/screens'
import { TCaptchaToken } from 'App/components/GoogleReCaptcha'

import styles from './SendEmailForm.module.scss'

type TValues = {
  email?: string
}

export const SendEmailForm = () => {
  const { push } = useHistory()
  const [email, setEmail] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const [successSubmitted, setSuccessSubmitted] = useState(false)
  const { disabledCaptcha, isProcessing, resetCaptcha, executeCaptcha, renderCaptcha } =
    useGoogleReCaptcha()

  const handleBack = () => {
    push(LOGIN_VIA_PASSWORD_URL)
  }
  const sendEmail = async (token: TCaptchaToken, paramsEmail?: string) => {
    setLoading(true)

    try {
      const { status } = await api.post(`${API.API_URL}${API.FORGOT_PASSWORD}`, {
        email: paramsEmail,
        recaptchaResponse: token
      })
      if (status === 201) {
        setLoading(false)
        setSuccessSubmitted(true)

        if (!email) {
          setEmail(paramsEmail)
        }
      }
    } catch (error) {
      setLoading(false)
      setSuccessSubmitted(false)
      toast.error(errorParsing(error), toastDefaultOptions)
    }
  }

  const onSubmit = (values: TValues) => {
    if (!disabledCaptcha) {
      resetCaptcha()
      executeCaptcha()
      setEmail(values.email)
    }

    if (disabledCaptcha) {
      sendEmail('', values.email)
    }
  }

  const submitted = email && successSubmitted

  return (
    <BrandCard
      title={submitted ? undefined : 'Forgot Password'}
      onBack={submitted ? undefined : handleBack}
    >
      {renderCaptcha((token) => sendEmail(token, email))}
      {submitted ? (
        <SuccessSubmitContent
          email={email}
          loading={loading || isProcessing}
          resendEmail={(newEmail) => onSubmit({ email: newEmail })}
        />
      ) : (
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <div className={styles.formFieldset}>
                <Field
                  name="email"
                  validate={validation.composeValidators(
                    validation.required(),
                    validation.isEmail(),
                    validation.maxLength(MAX_LENGTH_254)
                  )}
                >
                  {({ input, meta: { error, touched, invalid } }) => {
                    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
                      input.onChange(event.target.value.trim() as any)
                    }

                    return (
                      <TextField
                        {...input}
                        onChange={onChange}
                        autoFocus={true}
                        topLabel="Email*"
                        invalid={touched && invalid}
                        error={error}
                      />
                    )
                  }}
                </Field>
              </div>

              <Button
                loading={loading || isProcessing}
                type="submit"
                className={styles.button}
                disabled={isFormDisabled(form, ['email'])}
              >
                Restore
              </Button>
            </form>
          )}
        </Form>
      )}
    </BrandCard>
  )
}
