import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import { useQuery } from '@tanstack/react-query'
import { useHistory, useLocation } from 'react-router-dom'

import {
  TOption,
  Button,
  BrandCard,
  BrandCardActions,
  useGoogleReCaptcha,
  Spinner
} from 'App/components'
import { createSelectOption } from 'utils'
import { getUserLocation } from 'api/location'
import { TCaptchaToken } from 'App/components/GoogleReCaptcha'

import { useSignUpByEmailContext } from '../SignUpByEmailContext'
import { useAcceptInviteRequest } from '../useAcceptInviteRequest'
import { SignUpByEmailUserDataFields } from '../SignUpByEmailUserDataFields'

import styles from './NewAccount.module.scss'

type TValues = {
  firstName: string
  lastName: string
  profession: TOption
  city: TOption
  country: TOption
  joinToCommunity: boolean
}

type TLocation = {
  goBack?: boolean
}

export const NewAccount = () => {
  const { token, data } = useSignUpByEmailContext()

  const { isLoading, mutate } = useAcceptInviteRequest(data?.email)
  const { disabledCaptcha, isProcessing, resetCaptcha, executeCaptchaAsync, renderCaptcha } =
    useGoogleReCaptcha()

  const location = useLocation<TLocation>()

  const { goBack } = useHistory()

  const canGoBack = location.state?.goBack

  const { data: geoData, isFetching } = useQuery(['location-by-ip'], getUserLocation)

  const handleFormSubmit = useCallback(
    async (values: Partial<TValues>) => {
      let recaptchaResponse: TCaptchaToken = null

      if (!disabledCaptcha) {
        resetCaptcha()
        recaptchaResponse = await executeCaptchaAsync()
      }

      const { firstName, lastName, city, country, profession, joinToCommunity } = values

      if (firstName && lastName && city && country) {
        mutate({
          token,
          firstName,
          lastName,
          joinToCommunity,
          cityId: city.value,
          countryCode: country.value as string,
          professionId: profession?.value as string | undefined,
          recaptchaResponse
        })
      }
    },
    [disabledCaptcha, executeCaptchaAsync, mutate, resetCaptcha, token]
  )

  const initialValues = useMemo(() => {
    if (geoData) {
      return {
        city: geoData.city
          ? createSelectOption({ option: geoData.city, labelKey: 'cityName', valueKey: 'id' })
          : undefined,
        country: geoData.country
          ? createSelectOption({
              option: geoData.country,
              labelKey: 'countryName',
              valueKey: 'code'
            })
          : undefined
      }
    }

    return {}
  }, [geoData])

  const handleBackClick = useCallback(() => {
    if (canGoBack) {
      goBack()
    }
  }, [canGoBack, goBack])

  if (isFetching) {
    return <Spinner />
  }

  return (
    <>
      {renderCaptcha()}

      <BrandCard title="Fill Your Info" onBack={handleBackClick}>
        <Form onSubmit={handleFormSubmit} initialValues={initialValues}>
          {({ handleSubmit, form }) => (
            <form className={styles.form} onSubmit={handleSubmit}>
              <SignUpByEmailUserDataFields form={form} jointToCommunity={false} />

              <BrandCardActions
                center={
                  <Button
                    type="submit"
                    loading={isLoading || isProcessing}
                    disabled={form.getState().invalid}
                  >
                    Register
                  </Button>
                }
              />
            </form>
          )}
        </Form>
      </BrandCard>
    </>
  )
}
