import React, { FC } from 'react'
import { useHistory, Link } from 'react-router-dom'

import { Button } from 'App/components'
import { LOGIN_VIA_CODE_URL } from 'utils/urls'

import styles from './SuccessSubmitContent.module.scss'

export type TSuccessSubmitContentProps = {
  email?: string
  resendEmail?: (email?: string) => void
  loading?: boolean
}

export const SuccessSubmitContent: FC<TSuccessSubmitContentProps> = ({
  loading = false,
  email,
  resendEmail
}) => {
  const { push } = useHistory()

  const resend = () => {
    if (resendEmail) {
      resendEmail(email)
    }
  }

  return email && resendEmail ? (
    <>
      <div className={styles.promo}>
        Your request has been processed.
        <span> Please check your inbox to reset your password.</span>
      </div>
      <div className={styles.email}>{email}</div>

      <Button onClick={resend} loading={loading} className={styles.button}>
        Resend
      </Button>

      <Link className={styles.link} to={LOGIN_VIA_CODE_URL}>
        Go to Log In
      </Link>
    </>
  ) : (
    <>
      <div className={styles.promo}>Your Password has been changed.</div>
      <Button onClick={() => push(LOGIN_VIA_CODE_URL)} className={styles.button}>
        Go to Log In
      </Button>
    </>
  )
}
