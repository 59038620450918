import { InviteStatusEnum, PlatformInvitationType } from '@medentee/enums'

import { TCaptchaToken } from 'App/components/GoogleReCaptcha'
import { API, api } from 'services/api'
import { QueryBuilder } from 'utils'

export type TProfession = {
  id: string
  createdAt: string
  updatedAt: string
  deletedAt: string
  name: string
  subProfession: string
}

export type TCountry = {
  code: string
  countryName: string
}

export type TCity = {
  id: number
  cityName: string
  countryCode: string
  lng: string
  lat: string
  createdAt?: string
  updatedAt?: string
  deletedAt?: string
  timezone: string
}

export type TAccountDTO = {
  city: TCity
  country: TCountry
  firstName: string
  lastName: string
}

export type TInvitationDTO = {
  id: string
  status: InviteStatusEnum
  token: string
  type: PlatformInvitationType
  expiredAt: string

  email?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  deletedAt?: string | null
}

export type TPreRegistrationDataDTO = {
  email: string

  invitation?: TInvitationDTO
  account?: TAccountDTO
}

export const getDataByEmailToken = async (token: string) => {
  const { data } = await api.get<TPreRegistrationDataDTO>(
    `${API.API_URL}${API.PRE_REGISTRATION(token)}`
  )

  return data
}

export const getProfessions = async () => {
  const url = new QueryBuilder(`${API.API_URL}${API.PROFESSIONS}`).custom('types', '1').build()
  const { data } = await api.get<TProfession[]>(url)

  return data
}

export const getCountries = async () => {
  const { data } = await api.get<TCountry[]>(`${API.API_URL}${API.COUNTRIES}`)

  return data
}

export const getCities = async (countryCode?: string) => {
  const url = new QueryBuilder(`${API.API_URL}${API.CITIES}`)
    .custom('countryCode', countryCode)
    .build()
  const { data } = await api.get<TCity[]>(url)

  return data
}

type TAcceptInvitationPayload = {
  token: string
  firstName: string
  lastName: string
  cityId: string | number
  countryCode: string
  recaptchaResponse: TCaptchaToken

  professionId?: string
  joinToCommunity?: boolean
}

export const acceptInvitation = async ({
  token,
  cityId,
  countryCode,
  firstName,
  lastName,
  recaptchaResponse,
  professionId
}: TAcceptInvitationPayload) => {
  const { data } = await api.post(`${API.API_URL}${API.REGISTRATION(token)}`, {
    cityId,
    countryCode,
    firstName,
    lastName,
    recaptchaResponse,
    professionId
    /* This is hidden due to this task https://edento.atlassian.net/browse/MED-7858 */
    // joinToCommunity,
  })

  return data
}

type TGetRegistrationJWTPayload = {
  token: string
  email: string
  recaptchaResponse: TCaptchaToken
}

type TGetRegistrationJWTResponse = {
  jwt: string
}

export const getRegistrationJWT = async ({
  token,
  email,
  recaptchaResponse
}: TGetRegistrationJWTPayload) => {
  const { data } = await api.post<TGetRegistrationJWTResponse>(
    `${API.API_URL}${API.CONTINUE_VIA_JWT(token)}`,
    {
      email,
      recaptchaResponse
    }
  )

  return data
}
