import React, { FC, useEffect, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Link, RouteComponentProps } from 'react-router-dom'
import queryString from 'query-string'
import isArray from 'lodash/isArray'

import { API, api } from 'services/api'
import { toastDefaultOptions } from 'globalConstants'
import { errorParsing, isFormDisabled, validation } from 'utils'
import { SuccessSubmitContent } from 'App/screens'
import { PasswordField, PasswordStrengthChecker, toast, Button, BrandCard } from 'App/components'
import { MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH } from 'utils/validation/constants'
import { LOGIN_VIA_CODE_URL } from 'utils/urls'

import styles from './SendNewPasswordForm.module.scss'

type TForgotPasswordParams = {
  token?: string
}
type TValues = {
  password?: string
  repeatPassword?: string
}

export type TSendNewPasswordFormProps = RouteComponentProps<TForgotPasswordParams>

export const SendNewPasswordForm: FC<TSendNewPasswordFormProps> = ({
  history,
  location,
  match
}) => {
  const [passwordChange, setPasswordChange] = useState(false)
  const [loading, setLoading] = useState(false)
  const { token } = match.params

  const emailQuery = queryString.parse(location.search, { decode: false }).email ?? ''
  const email = isArray(emailQuery) ? emailQuery[0] : emailQuery

  const getResetPasswordData = async () => {
    setLoading(true)

    try {
      setLoading(false)
      await api.get(`${API.API_URL}${API.FORGOT_PASSWORD_VERIFY_TOKEN}/${token}/${email}`)
    } catch (e) {
      setLoading(false)
      history.push(LOGIN_VIA_CODE_URL)
      toast.error(errorParsing(e), toastDefaultOptions)
    }
  }

  const onSubmit = async ({ password, repeatPassword }: TValues) => {
    if (!password) {
      return
    }

    setLoading(true)

    const queryEmail = queryString.stringify({ email })

    try {
      await api.put(`${API.API_URL}${API.FORGOT_PASSWORD}/${token}?${queryEmail}`, {
        password,
        confirmPassword: repeatPassword
      })

      setLoading(false)
      setPasswordChange(true)
    } catch (error) {
      setLoading(false)
      setPasswordChange(false)
      toast.error(errorParsing(error), toastDefaultOptions)
    }
  }

  useEffect(() => {
    getResetPasswordData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BrandCard title={passwordChange ? undefined : 'Enter a new Password'}>
      {passwordChange ? (
        <SuccessSubmitContent />
      ) : (
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.formFieldset}>
                <Field
                  name="password"
                  validate={validation.composeValidators(
                    validation.required(),
                    validation.minLength(MIN_PASSWORD_LENGTH),
                    validation.maxLength(MAX_PASSWORD_LENGTH),
                    validation.isPassword('Incorrect entry')
                  )}
                >
                  {({ input }) => (
                    <PasswordField {...input} autoFocus={true} topLabel="Set Password*" />
                  )}
                </Field>

                <PasswordStrengthChecker
                  password={form.getFieldState('password')?.value ?? ''}
                  repeatPassword={form.getFieldState('repeatPassword')?.value ?? ''}
                />

                <Field
                  name="repeatPassword"
                  validate={validation.composeValidators(
                    validation.required(),
                    validation.isPasswordConfirm()
                  )}
                >
                  {({ input }) => <PasswordField {...input} topLabel="Repeat Password*" />}
                </Field>
              </div>

              <Button
                loading={loading}
                type="submit"
                className={styles.button}
                disabled={isFormDisabled(form, ['password', 'repeatPassword'])}
              >
                Confirm
              </Button>

              <Link className={styles.link} to={LOGIN_VIA_CODE_URL}>
                Go to Log In
              </Link>
            </form>
          )}
        </Form>
      )}
    </BrandCard>
  )
}
